import React from 'react'
import './App.css';
//components
import Canva from './components/Canva';
import Sparkle from './components/Sparkle';

const App = () => {
  return (
   <>
       <Canva></Canva>
        <Sparkle></Sparkle>  
    </>
  )
}

export default App;
